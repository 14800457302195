import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import ExpertHelp from "../components/ExpertHelp"
import { SectionPage, Container, SectionPageTitle, } from "../components/Section"
import HeroPpcBanner from "../components/HeroPpcBanner"
import ReviewSliderWidget from "../components/ReviewSliderWidget"
import EstimateFrom from "../components/EstimateForm"
import CategoryTabs from "../components/CategoryTabs"
import Service from "../components/Service"
import { Helmet } from "react-helmet";


const PopularMetalBuildingPage = ({ data, location, formName }) => {
  const homePageData = data.contentfulHome
  const TestimonialSectionData = homePageData.reviewSection
  const TestimonialData = data.allContentfulTestimonial

  const pageData = data.contentfulProductCategoryLanding
  const totalProducts = data.allContentfulProduct.edges
  const currentData = totalProducts.filter(
    edge => edge.node.productCategory && edge.node.productCategory.name === pageData.category.name
  )

  const renderProductList = () => {
    return (
      <CategoryTabs
        data={totalProducts}
        category={pageData.category.name}
        location={location}
      />
    )
  }

  return (
    <Layout location={location}>
      <Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
      <SEO title="Popular Metal Buildings" description="Popular Metal Buildings"/>
      <HeroPpcBanner location={location} />
      <SectionPage ept="60px" epb="60px" xpt="40px" xpb="40px" pt="30px" pb="30px" bg="#F4FBFF">
        <Container>
          <SectionPageTitle textAlign="center">Find the Perfect Metal Building for Your Needs</SectionPageTitle>
          {renderProductList()}
        </Container>
      </SectionPage>
      <EstimateFrom location={location} />
      <Service />
      <ExpertHelp />
      <ReviewSliderWidget sectionData={TestimonialSectionData} data={TestimonialData} />
      <CallToAction />
    </Layout>
  )
}

export default PopularMetalBuildingPage

export const pageQuery = graphql`
  query popuplarBuildingQuery{
    contentfulProductCategoryLanding {
      title
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      productListTitle
      formSection {
        title
        features {
          title
          roof
          image
          description
        }
      }
      category {
        name
      }
      content {
        content
      }
    }
    allContentfulProduct(sort: { fields: shopOrder, order: ASC }) {
      edges {
        node {
          id
          senseiProductId
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          gauge
          productServices {
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          description {
            description
          }
          numberOfSale
          canBeUsedAs {
            content
          }
        }
      }
    }
    allContentfulTestimonial {
      edges {
        node {
          customerName
          description {
            description
          }
        }
      }
    }
    contentfulHome {
      reviewSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
