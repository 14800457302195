import React, { useState, useEffect } from "react"
import { Container } from "../../components/Section"
import PhoneIcon from "../../components/Icons/PhoneIcon"
import SecondaryButton from "../../components/Button/SecondaryButton"
import QuotePopup from "../../components/QuotePopup"
import {HeroCoverWrap, HeroCoverBanner, HeroCaption, HeroInnerCaption, HeroButton, HeroItemButton} from "../../components/HeroBannerStyle"
import { StaticImage } from "gatsby-plugin-image"


const HeroPpcCover = ({ location }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const HandleModalOpen = () => {
    setIsModalVisible(true)
  }
  const HandleModalClose = () => {
    setIsModalVisible(false)
  }
  useEffect(() => {
    if (isModalVisible) {
      document.querySelector("body").classList.add("modal-open")
    } else {
      document.querySelector("body").classList.remove("modal-open")
    }
  }, [isModalVisible, setIsModalVisible])

  return (
    <>
      <HeroCoverWrap>
        <HeroCoverBanner>
          <StaticImage src="../../images/hero-banner.jpg" alt="Metal Buildings" layout="fullWidth" />
        </HeroCoverBanner>
        <HeroCaption>
          <Container maxWidth="962px">
            <HeroInnerCaption>
              <h1>Durable, Custom Metal Buildings That Last</h1>
              <p>From design to delivery, we provide high-quality, long-lasting steel structures</p>
              <HeroButton>
                <HeroItemButton className="hero-btn">
                  <a href="tel:8777541818" aria-label="(877) 754-1818">
                    <SecondaryButton text="Call Us Now" icon={<PhoneIcon />} />
                  </a>
                </HeroItemButton>
                <HeroItemButton className="hero-btn">
                  <button
                    type="button"
                    aria-label="Get A Free Quote"
                    onClick={HandleModalOpen}
                  >
                    <SecondaryButton text="Get A Free Quote" />
                  </button>
                </HeroItemButton>
              </HeroButton>
            </HeroInnerCaption>
          </Container>
        </HeroCaption>
      </HeroCoverWrap>
      <QuotePopup
        isVisible={isModalVisible}
        onClose={HandleModalClose}
        location={location}
        formName="HomePage Quote Form"
      />
    </>
  )
}

export default HeroPpcCover
